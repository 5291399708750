@mixin phone {
  @media (max-width: 599px) { @content; }
}
@mixin tablet {
  @media (min-width: 600px) { @content; }
}
@mixin desktop {
  @media (min-width: 900px) { @content; }
}

/*
@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("../src/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
}hier

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../src/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 500;
}
*/

/*
body * {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 300;
}
*/


:root {
  --vinColor: #520CA7;
	--primary: #0091ff;
	--dark: #061b28;
	--gray: #756388;
	--light: #ffffff;
  overflow-y: hidden; // Scrollbar verbergen
}

.App {
  text-align: center;
  font-family: "Poppins";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.sidebarMainTitle {
  /*background-color: rgba(0, 0, 0, 0.9) !important;*/
  background-color: rgb(70, 76, 192) !important;
}

.sidebarMainContent
{
  /*background-color: rgba(0, 0, 0, 0.7) !important;*/
  background-color: rgb(60, 66, 166) !important;
}


.container-fluid {
  padding: 0px !important;
}



h1 {
  font-size: 24px !important;
}

.lead {
  font-weight: 400 !important;
  font-size: 18px !important;
}


.mainActions {
  display: flex;
  justify-content: space-between; //flex-start
  flex-direction: row;
  margin: 0px !important;
  align-items: center;
  padding: 10px 10px;
  //max-height: 75px;
  @mixin tablet {
    flex-direction: column !important;
    //max-height: 75px;
  }
  @mixin mobile {
    flex-direction: column !important;
    //max-height: 75px;
  }

  .actionIcon {
    padding-left: 10px;
    cursor: pointer;
  }

}

.meldungen {
 
  padding: 0px;

  .alert {
    margin: 8px !important;
 
  }
}



.noActionArea {
  margin-top: -15px;   
}

.mainWork {

  background-color: #e3e4e7;
  padding: 12px 12px 100px 12px;
  //border: 2px solid black;
  height: calc(100vh - 30px);
  overflow-y: scroll;
  overflow: auto;
  /*
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  */
  &:hover {
    color: black !important;
  }

  @mixin mobile {
    //...
  }

  input[type=text]:read-only {
    background: lightgrey;
  }

  input[type=text]::placeholder {
    opacity: 0.6;
  }
  input[type=url]::placeholder {
    opacity: 0.6;
  }
  input[type=number]::placeholder {
    opacity: 0.6;
  }
  textarea::placeholder {
    opacity: 0.6;
  }

  .readOnly {
    background: lightgrey;
    caret-color: transparent; // Cursor verbergen

    &:focus {
      background: lightgrey;
      caret-color: transparent; // Cursor verbergen
    }
  }

  fieldset {
    margin: 10px !important;
  }


/* Tab Navigation */
.nav-tabs
.nav-link.active{
    color: #c58164;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.nav-link{
    color: black;
}




.container{
    margin-top: 0px;
    z-index: 1000;
   /* @include tablet {
        margin-top: 50px;
    }*/
}


  .form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
    opacity: 0.75;
  }



  fieldset {
    padding: 10px 25px 20px 25px !important;
    margin-bottom: 20px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .form-label {
    margin-top: 10px;
    margin-bottom: 0px;
  }

} /* end Main Work */


.customModal {
  .readOnly {
    background: rgb(224, 224, 224);
    caret-color: transparent; // Cursor verbergen

    &:focus {
      background:rgb(224, 224, 224);
      caret-color: transparent; // Cursor verbergen
    }
  }
}

/*
.slide-in-right-enter {
  transform: translateX(-100%);
}

.slide-in-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-right-exit {
  transform: translateX(0%);
  opacity: 1;
}
*/



/* Sidebar Filter */

.right-to-left {
  &-enter {
    opacity: 0.01;
    transform: translateX(100%);
  }
  &-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 250ms ease-in-out;
  }
  /*
   &-enter-done {
      opacity:1; 
      transition: opacity 500ms ease-in-out;
  }
  */
  &-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &-exit-active {
    opacity: 0.01;
    transform: translateX(100%);
    transition: all 350ms ease-out;
  }
}
         

/*
.left-to-right-enter {
  transform: translateX(-100%);
}
.left-to-right-enter-active {
  transform: translateX(0);
  transition:all 1s ease;
}      

.left-to-right-exit {
  transform: translateX(0);
}
.left-to-right-exit-active {
  transform: translateX(100%);
  transition:all 1s ease;
}     
*/ 
