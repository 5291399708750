.sidefilter {
    height: 100%;
    margin-top: 160px !important;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background: red !important;
    /*overflow-x: hidden;*/
    padding-top: 80px !important;
  }