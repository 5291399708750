/*
@mixin mobile {
    @media screen and (max-width: 480px) {
      @content;
    }
  }
  @mixin tablet {
    @media screen and (max-width: 768px) {
      @content;
    }
  }
  @mixin laptop {
    @media screen and (max-width: 1200px) {
      @content;
    }
  }
  */


  .nav-tabs
  .nav-link.active{
      color: #c58164;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .nav-link{
      color: black;
  }
  
  .container{
      margin-top: 0px;
      z-index: 1000;
     /* @include tablet {
          margin-top: 50px;
      }*/
  }