.uploadBtn {
    width: auto;
    border: none;
    background: #520ca7;
    color: white;
    font-weight: 600;
    font-size: 110%;
    text-align: center;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
}