.bootstrap-slider-container {
    width: 60%;
}

.bootstrap-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.bootstrap-slider:hover {
    opacity: 1;
}

.bootstrap-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none; 
    width: 18px;
    height: 18px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 50%;
}

.bootstrap-slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 50%;
}