.whatsapp-chat {
  .col-9{
     .right-side{
         height: 100%;
         .row{
             height: 100%;
             .col-8{
                 transition: all .5s;
                 .message-send-show{
                    height: calc(100vh - 100px); /* Header, Tabs, .. */
                     border-right: 1px solid var(--border-color);
                     display: flex;
                     flex-direction: column;
                     justify-content: space-between;
                     .header{
                         display: flex;
                         flex-direction: row;
                         align-items: center;
                         justify-content: space-between;
                         padding:  12px 10px;
                         border-bottom: 1px solid var(--border-color);
                         .image-name{
                             display: flex;
                             .image{
                                 position: relative;
                                 width:  40px;
                                 height: 40px;
                                 
                                 img{
                                     width:  40px;
                                     height: 40px;
                                     border-radius: 50%;
                                 }  
                                 .active-icon{
                                     position: absolute;
                                     width: 10px;
                                     height: 10px;
                                     border-radius: 50%;
                                     background-color: rgb(28, 212, 28);
                                     right: 0px;
                                     bottom: 0pc;
                                 } 
                             }
                             .name{
                                 display: flex;
                                 justify-content: center;
                                 align-items: center;
                                 h3{
                                    padding-left: 10px;
                                    color: var(--text-color);
                                    margin: 0px;
                                    font-size: 110%;
                                 }
                             }
                         }
                         .icons{
                             display: flex;
                             .icon{
                                 height: 35px;
                                 width: 35px;
                                 border-radius: 50%;
                                 margin-left: 8px;
                                 border-radius: 24px;
                                 color: var(--icon-color);
                                 cursor: pointer;
                                 background-color: var(--icon-bg-color);
                                 display: flex;
                                 justify-content: center;
                                 align-items: center;
                                 label{
                                     display: flex;
                                     margin-bottom: 0px;
                                     cursor: pointer;
                                     color: var(--icon-color);;
                                 }
                             }
                         }
                     }
                 }
             }
              
         }
         #dot{
             display: none;
         }
         #dot:checked ~ .row .col-4{
             display: none;
         }
         #dot:checked ~ .row .col-8{
             width: 100%;
         }
         #dot:checked ~ .row .col-8 .message-show .my-message .my-text{
             width: 500px;
         }
         #dot:checked ~ .row .col-8 .message-show .fd-message .fd-text{
             width: 500px;
         }
     }
  }

  
}