.whatsapp-chat {

    .message-show{
        /*background-color: #F0E9DF;*/
        background-image: url('./images/wa-chat-bg.png');
        height: 100%;
        padding: 10px;
        overflow-y: auto;
        &::-webkit-scrollbar{
            overflow:hidden;
        }
        .friend_connect{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            color: var(--text-color);
            img{
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }
            h3{
                margin-top: 10px;
            }
        }
        .my-message{
            float: right;
            width: 100%;
            margin-bottom: 10px;
            .my-text{
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                width: 370px;
                flex-direction: column;
                .my{
                    background-color: var(--my-message-bg);
                    color: var(--text-white);
                }
                .message-text{
                    text-align: start;
                    padding: 10px;
                    border-radius: 10px;
                    margin: 0px;

                    img{
                        width: 220px;
                        height: 270px;
                        margin-right: 0px;
                        border-radius: 5px;
                        overflow: hidden;
                        object-fit: cover;
    
                    }
                }
            }
            .time{
                text-align: end;
                padding: 4px 4px 0px 0px;
                color: #9e9e9e;
                font-size: 12px;
            }
        }
        .fd-message{
            float:  left;
            width: 100%;
            margin-bottom: 10px;
            .fd-text{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                width: 370px;
                .fd{
                    background-color: var(--fd-message-bg);
                    color: var(--text-color);
                }
                .message-text{
                    text-align: start;
                    border-radius: 10px;
                    margin: 0px;
                    img{
                        width: 220px;
                        height: 270px;
                        margin-right: 0px;
                        border-radius: 5px;
                        overflow: hidden;
                        object-fit: cover;
    
                    }
                }
            }
        }
    }

    /* ICH */
    .message-image-time,.image-message{
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        img{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            margin-left: 10px;
        }
        .message-text{
            padding: 8px;
            color: #333;
            background-color: #D8FDD4;
            font-size: 14px;
            font-weight: 400;
        }
    }


    /* FREUND */
    .image-message-time,.image-message{
        display: flex;
        min-width: 100%;
        img{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
        }
        .message-text{
            padding: 8px;
            color: #333;
            background-color: #fff;
            font-size: 14px;
            font-weight: 400;
        }
        .time{
            padding: 4px 0px 0px 2px;
            color: #828282;
            font-size: 12px;
        }
    }
    .typing-message{
        padding: 0px 10px;
    }


}