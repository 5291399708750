.whatsapp-chat {
    .messenger{
        width: 100%;
        height: calc(100vh - 100px); /* Header, Tabs, .. */
        background-color: #ffffff;
        .row{
            height: 100%;
            .col-3{
                max-width: 350px;
                .left-side{
                    border-right: 1px solid var(--border-color);
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: start;
                    .top{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin: 12px 8px;
                        .image-name{
                            display: flex;
                            cursor: pointer;
                            .image{
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                overflow: hidden;
                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .name{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    padding-left: 10px;
                                    font-weight: 600;
                                    color: var(--text-color);
                                }
                            }
                        }
                        .icons{
                            display: flex;
                            position: relative;
                            .theme_logout.show{
                                transform: scale(1);
                            }
                            .theme_logout{
                                width: 200px;
                                background-color: rgb(20, 24, 59);
                                transform: scale(0);
                                position: absolute;
                                transition:  all .6s;
                                top: 100%;
                                padding: 10px;
                                color: #fff;
                                .on,.of{
                                    label{
                                        cursor: pointer;
                                        color: #fff;
                                    }
                                    display: flex;
                                    justify-content: space-between;
                                    margin-top: 10px;
                                }
                                .logout{
                                    display: flex;
                                    align-items: center;
                                    margin-top: 10px;
                                    cursor: pointer;
                                }
                            }
                            .icon{
                                width: 33px;
                                height: 33px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: var(--icon-bg-color);
                                margin-left: 8px;
                                border-radius: 24px;
                                color: var(--icon-color);
                                cursor: pointer;
                            }
                        }
                    }
                    .friend-search{
                        padding: 0px 8px;
                        .search{
                            display: flex;
                            width: 100%;
                            height: 40px;
                            background-color: var(--search-bg);
                            border-radius: 50px;
                            margin: 10px 0px 20px 0px;
                            button{
                                padding: 12px;
                                border-bottom-left-radius: 50px;
                                border-top-left-radius: 50px;
                                border: none;
                                color: var( --text-color);
                                font-size: 20px;
                                background-color: transparent;
                            }
                            .form-control{
                                width: 100%;
                                padding-left: 0px;
                                border-bottom-right-radius: 50px;
                                border-top-right-radius: 50pc;
                                background-color: #fff0;
                                border: none;
                                color: var(--text-color);
                                &::placeholder{
                                    color: var( --text-color);
                                }
                            }
                        }
                    }
                }
            }
        }
    }


}