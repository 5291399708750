.waTemplate {
    width: 100%;
    padding: 8px 8px 8px 15px;
    margin: 10px 0px;
    cursor: pointer;
    border-radius: 8px;
}

.cardMedia {
    min-width: 110px;
    margin: 10px;
    border-radius: 8px;
    background: white;
    padding: 16px;
    text-align: center;
}
