.whatsapp-chat {
    
    .message-send-section{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 5px;
        background-color: #F1F2F6;
        .file{
            display: flex;
            padding: 8px;
            font-size: 20px;
            cursor: pointer;
            color: var(--icon-color);
            label{
                display: flex;
                align-items: center;
                margin-bottom: 0px;
                cursor: pointer;
                color: var(--icon-color);
            }
            #pic{
                display: none;
            }
            .add-image,.add-attachment,.add-gift{
                position: fixed;
                padding: 4px;
                background: #212d44;
                font-size: 14px;
                bottom: 44px;
                display: none;
                color: var(--text-white);
            }
        }
        .hover-attachment{
            position: relative;
            &:hover{
                .add-attachment{
                    display: block;
                }
            }
        }
        .hover-image{
            position: relative;
            &:hover{
                .add-image{
                    display: block;
                }
            }
        }
        .hover-gift{
            position: relative;
            &:hover{
                .add-gift{
                    display: block;
                }
            }
        }
        .message-type{
            display: flex;
            width: 70%;
            height: 40px;
            background-color: #dfdfdf;
            border-radius: 50px;
            border: none;
            align-items: center;
            .form-control{
                width: 100%;
                border-bottom-left-radius: 50px;
                border-top-left-radius: 50px;
                background-color: #fff; /*transparent;*/
                border: none;
                &::placeholder{
                    color: var(--text-color);
                }
            }
            label{
                font-size: 20px;
                margin-right: 5px;
                cursor: pointer;
            }
        }
        .emoji-section{
            width: 200px;
            background: #0e131d;
            position: absolute;
            right: 60px;
            padding: 6px;
            bottom: 50px;
            border-radius: 8px;
            border-bottom-right-radius: 0px;
            .emoji{
                display: grid;
                grid-template-columns: repeat(7,1fr);
                grid-gap: 4px;
                span{
                    cursor: pointer;
                }
            }
        }
        #emoji{
            display: block;
            cursor: pointer;
        }

        .emoji-section {
            display: none;
          
            &.show {
              display: block !important;
            }
          }
    }


}