.whatsapp-chat {
    .friends{
        display: flex;
        flex-direction: column;
        background-color: #fff; /*var(--border-color);*/
        height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar{
            display: none;
        }
        .hover-friend.active{
            background-color: var(--search-bg);
        }
        .hover-friend{
            cursor: pointer;
            &:hover{
                background-color: #F1F2F6;
            }
            .friend{
                display: flex;
                width: 100%;
                padding: 10px 15px;
                .friend-image{
                    .image{
                        margin-right: 8px;
                        position: relative;
                        width: 40px;
                        height: 40px;
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            /*border: 1px solid var(--text-color);*/
                            box-shadow: 10px 10px 16px 0 rgba(0,0,0,0.05), 0 6px 20px 0 rgba(0,0,0,0.19);
                        }
                        .active_icon{
                            position: absolute;
                            width: 13px;
                            height: 13px;
                            border-radius: 50%;
                            background-color: rgb(43, 228, 43);
                            right: -2px;
                            bottom: 6px;
                            border: 1px solid #fff;
                        }
                    }
                }
                .friend-name-seen{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .friend-name{
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        flex-direction: column;
   
                        .username-line{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            padding-bottom: 5px;

                            h4.unseen_message{
                                font-weight: 700 !important;
                                font-size: 95%;
                                margin: 0px;
                            }
                            h4{
                                color: var(--text-color);
                                font-weight: 500;
                                font-size: 95%;
                                margin: 0px;
                            }

                            span{
                                margin: 0 -30px 0 0;
                            }
                        }
                        .msg-time{

                            display: inline-block; /* or block */
                            max-width: 230px; /* Adjust as needed */
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            span.unseen_message{
                                font-weight: 700 !important;
                            }
                            span{
                                font-size: 12px;
                                color: var(--text-color);
                            }
                        }
                    } 
                    .seen-unseen-icon{
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        width: 100%;
                        height: 100%;
                        img{
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                        }
                        .unseen,.delivered{
                            color: var(--text-color);
                        }
                        .seen-icon{
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                            background-color: #1e6bbd;
                        }
                    }
                }
                
            }
        }
    }


}