/*.side-nav-container img {
	overflow: visible !important;
	width: 25px !important;
	height: 25px !important;
 }
 */
  



/* NX = not expanded */

.side-nav-container {
	/* Unter Main Navbar */
	/*margin-top: 40px;
	height: calc(100vh - 40px);
	*/
  
	/* Über Main Navbar */
	z-index: 10;
	margin-top: 35px;
	min-height: calc(100vh - 35px); /* immer 100% Höhe - Topbar */
	position: fixed !important;
	/*
	overflow-y: auto;
	overflow-x: hidden;
	*/


	background-color: var(--dark);
	width: 300px;
	position: relative;
	color: var(--light);
	transition: 0.4s;
}

.side-nav-container-NX {
	width: 85px;
}


.menu-item {
	display: flex;
    justify-content: flex-start;
    align-items: center;
	padding-left: 8px;
	padding-right: 8px;
}

.nav-upper,
.nav-heading,
.nav-menu,
.nav-footer {
	/* border: 2px solid white; */
	display: grid;
}


/* React Scrollbar .ScrollbarsCustom-ThumbY */
.ScrollbarsCustom-Thumb {
	background: lightgrey !important;
}

.ScrollbarsCustom-Track {
	background: rgba(200, 200, 200, 0.4) !important;
}

.nav-heading {
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr;
	height: 75px;
}

.nav-brand {
	display: flex;
	color: var(--light);
}
.nav-brand img {
	width: 40px;
	padding: 0 10px;
}

.hamburger {
	background: none;
	border: none;
	cursor: pointer;
	margin: auto;
}

.hamburger span {
	margin-left: -4px;
	display: block;
	margin-top: 6px;
	background-color: var(--light);
	border-radius: 5px;
	height: 4px;
	width: 35px;

	transition: 0.4s;
}

.hamburger:hover span {
	background-color: var(--primary);
}

.hamburger-in {
	/* wegen Scrollbar... 
	margin-right: 50px;
	*/
}

.hamburger-in:hover span:nth-child(1) {
	width: 25px;
	transform: translateY(4px) rotate(-25deg);
}
.hamburger-in:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-in:hover span:nth-child(3) {
	width: 25px;
	transform: translateY(-4px) rotate(25deg);
}
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
.hamburger-out {
	margin-left: 25px;
}
.hamburger-out:hover span:nth-child(1) {
	width: 25px;
	transform: translate(14px, 4px) rotate(-155deg);
}
.hamburger-out:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-out:hover span:nth-child(3) {
	width: 25px;
	transform: translate(14px, -4px) rotate(155deg);
}

.nav-menu {
	grid-template-rows: repeat(7, 1fr);
	margin-top: 0px;
}

.menu-item {
	height: 50px;
	display: flex;
	color: var(--light);
	text-decoration: none;
	/*text-transform: uppercase;*/
	margin: auto 20px;
	border-radius: 10px;
}

.menu-item p {
	margin: 0px;
}

.menu-item-NX {
	margin: auto;
}
.menu-item:hover {
	color: var(--dark);
	background-color: var(--light);
}



.nav-footer {
	width: 100%;
	height: 87px;
	position: absolute;
	bottom: 0;
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
}

.side-nav-container:hover {
	color: var(--light) !important;
}

/*
.nav-details {
	display: flex;
}
.nav-details img {
	width: 50px;
	padding: 0 20px;
}
*/

.nav-footer-user-name {
	font-size: 18px;
	font-weight: 900;
}

.nav-footer-user-position {
	margin-top: -15px;
	color: var(--gray);
}
.logout-icon {
	width: 30px;
	margin: auto;
	border-radius: 90px;
	padding: 20px;
	margin-left: 5px;
}
.logout-icon:hover {
	color: var(--dark);
	background-color: var(--light);
}