.whatsapp-chat {
     
     .row{
          display: flex;
          /*width: 100%; erzeugt whitespace bin meinem projekt.. */
     }

     /* Linke Seitenleiste */
     .col-3{
          padding: 0px 0px 0px 12px !important;
     }

     /* Rechter Bereich - Chat-Bereich + Kontaktinfo/Medien */
     
     .col-9{
          flex-grow: 1; /* Adjust remaining space because contact list max-width 350px! */
     }


     /* Chat-Bereich -  Chat */
     .col-8{
          padding: 0px !important;
     }

     @for $i from 1 through 12{
          .col-#{$i}{
               width: 100%/12*$i;
          }
     }

}