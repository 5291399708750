.cardContainer {
    display: flex;
    flex-direction: column;
    /*height: 100%;*/
    margin-right: 10px;
    margin-bottom: 12px;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 16px;
}

.cardContainerUpload {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
}

.cardContainerSub {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 12px;
    padding: 10px 20px;
    margin: 8px 10px;
    cursor: pointer;
}

.cardImg {
   max-width: 150px;
   width: auto;
   object-fit: contain;
   aspect-ratio: 200/150;
   padding: 5px 10px;
  
}

.cardDate {
    font-size: 85%;
    margin: 5px 0;
    color: #171717;
}

.cardText {
    margin: 0;
    color: #171717;
}

.cardTextSub {
    margin: 0;
    color: #171717;
    font-weight: 600;
}


@media screen and (max-width:762px) {

    .cardContainer {
        margin: 10px 10px;
        /*background: red;*/
    }

    .cardContainerSub {
        margin: 8px 8px;
        /*background: red;*/
    }
    
}